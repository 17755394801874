import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { ParallaxProvider, Parallax } from 'react-scroll-parallax';
import 'intersection-observer';
import Observer from '@researchgate/react-intersection-observer';
import TransitionLink from 'gatsby-plugin-transition-link';
import gsap, { Expo } from 'gsap';
import Layout from '../components/layout';
import Transition from '../components/transition';
import Header from '../components/header';
import PublishedDoya from '../components/published-do-ya';
import PublishedKyotocityKyoceraMuseum from '../components/published-kyotocity-kyocera-museum';
import PublishedPomagazine from '../components/published-pomagazine';
// import Instagram from '../components/instagram';
import Footer from '../components/footer';
import { InitTransition } from '../components/init-transition';
import Style from '../styles/index/index.module.scss';
import RojitekiSp from '../images/index/rojiteki_sp.svg';
import RojitekiPc from '../images/index/rojiteki_pc.svg';
import PublishedUro from '../components/published-uro';
import PublishedArtWalkKyoto from '../components/published-art-walk-kyoto';
import PublishedFoodDesign from '../components/published-food-design';
import PublishedSeesaw from '../components/published-seesaw';
import PublishedSavvy from '../components/published-savvy';

class Index extends React.Component {
  constructor() {
    super();
    this.child = React.createRef();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  headingAnimation = (e, flag = '') => {
    if (flag === '') {
      e.className = 'clip on';
    } else {
      e.className = `clip clip${flag} on`;
    }
  };

  introAnimation = (e) => {
    requestAnimationFrame(() => {
      gsap
        .to(e, {
          duration: 0.7,
          opacity: 1,
          translateX: '0',
          ease: Expo.easeOut,
        });
    });
  };

  servicesLeft = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const headingServices = document.querySelector('.init_a_headingServices');
      const introServices = document.querySelector('.init_a_introServices');
      this.headingAnimation(headingServices);
      this.introAnimation(introServices);
      unobserve();
    }
  };

  servicesRight = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const servicesItem = document.querySelectorAll('.init_a_servicesItem');
      requestAnimationFrame(() => {
        gsap
          .to(servicesItem, {
            duration: 1,
            translateX: 0,
            opacity: 1,
            stagger: 0.2,
            ease: Expo.easeOut,
          });
      });
      unobserve();
    }
  };

  works = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const isWorks = 'Works';
      const headingWorks = document.querySelector('.init_a_headingWorks');
      const introWorks = document.querySelector('.init_a_introWorks');
      this.headingAnimation(headingWorks, isWorks);
      this.introAnimation(introWorks);
      unobserve();
    }
  };

  ourteam = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const headingOurteam = document.querySelector('.init_a_headingOurteam');
      const introOurteam = document.querySelector('.init_a_introOurteam');
      this.headingAnimation(headingOurteam);
      this.introAnimation(introOurteam);
      unobserve();
    }
  };

  culture = ({ isIntersecting }, unobserve) => {
    if (isIntersecting) {
      const headingCulture = document.querySelector('.init_a_headingCulture');
      const introCulture = document.querySelector('.init_a_introCulture');
      this.headingAnimation(headingCulture);
      this.introAnimation(introCulture);
      unobserve();
    }
  };

  render() {
    const IndexQuery = graphql`
      {
        illustration01: file(relativePath: { eq: "illustration/illustration01.png" }){
          childImageSharp {
            fluid(maxWidth: 600, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        illustration02: file(relativePath: { eq: "illustration/illustration02.png" }){
          childImageSharp {
            fluid(maxWidth: 720, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        illustration03: file(relativePath: { eq: "illustration/illustration03.png" }){
          childImageSharp {
            fluid(maxWidth: 1080, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
        illustration04: file(relativePath: { eq: "illustration/illustration04.png" }){
          childImageSharp {
            fluid(maxWidth: 1080, quality: 70) {
              ...GatsbyImageSharpFluid_noBase64
            }
          }
        },
      }
    `;

    const transition = {
      InitTransition,
      animation: () => {
        this.child.current.exitAnimation(transition.InitTransition.length);
      },
    };

    const options = {
      root: null,
      rootMargin: '-50px',
      threshold: 0,
    };

    return (
      <StaticQuery
        query={IndexQuery}
        render={(data) => (
          <Layout>
            <Transition ref={this.child}>
              <Header transition={transition} current="index" />
              <ParallaxProvider>
                <main className="top">
                  <section className={Style.rojiteki}>
                    <div className={Style.visual}>
                      <figure className={`spTabVDisBlock ${Style.copy}`}>
                        <img src={RojitekiSp} alt="路地的。" />
                      </figure>
                      <Img
                        className={Style.illustration}
                        fluid={data.illustration01.childImageSharp.fluid}
                        alt="路地的"
                      />
                    </div>
                    <div className={`inner ${Style.inner}`}>
                      <p className={Style.japanese}>
                        碁盤の目の町を縫うように、路地がひしめく京都。
                        <br className="pcDisBlock" />
                        その数、約１３０００本とも。
                        <br className="pcDisBlock" />
                        そこは、Googleでさえ立ち入れない世界。
                        <br />
                        何気ない道に価値を見出すように、
                        <br className="pcDisBlock" />
                        ものの見方を変えたり、前提となっている常識を疑う。
                        <br className="pcDisBlock" />
                        丁寧に、観察するように、目をこらして。
                        <br className="pcDisBlock" />
                        そして、地域に生きる人と顔をあわせ、
                        <br className="pcDisBlock" />
                        わずかな機微を感じ取る。
                        <br className="pcDisBlock" />
                        情報の媒介者としてバンクトゥが大切にしたいのは、
                        <br className="pcDisBlock" />
                        まさに路地をゆくような態度だ。
                        <br />
                        豊かな探求心をもち、知らない道へ踏み出してみる。
                        <br className="pcDisBlock" />
                        その視点が、社会を変える新たな視座になる。
                      </p>
                      <Parallax className={`tabHPcDisBlock ${Style.copy}`} y={[-10, 10]} tagInner="figure">
                        <img src={RojitekiPc} alt="路地的。" />
                      </Parallax>
                      <p className={`tabHPcDisBlock ${Style.english}`}>
                        Kyoto has many alleys like sewing a city like a go board.
                        <br />
                        The number is about 13,000.
                        <br />
                        It's a place where even Google cannot access.
                        <br />
                        To find value on the casual road,
                        <br />
                        Change the way you look at things or doubt the common sense that is presupposed.
                        <br />
                        Look carefully, as if observing.
                        <br />
                        And meet the people who live in the area,
                        <br />
                        Feel the slight change.
                        <br />
                        What banktoLLC. wants to value as a mediator of information is:
                        <br />
                        It's like walking in an alley.
                        <br />
                        With curiosity, walk on an unknown street.
                        <br />
                        That perspective becomes a new perspective that changes society.
                      </p>
                    </div>
                    <div className="scroll" />
                  </section>
                  <section className={`section ${Style.services}`}>
                    <Observer {...options} onChange={this.servicesLeft}>
                      <div className={Style.left}>
                        <h2 className="heading">
                          <span className="text">Services<br className="spTabHDisBlock" /> & Value</span>
                          <span className="clip init_a_headingServices">Services<br className="spTabHDisBlock" /> & Value</span>
                        </h2>
                        <p className="intro init_a_introServices">バンクトゥは「編集」という職能をベースに、戦略設計・デザイン・エンジニアリングをカバーするクリエイティブ・ファームです。ものの見方を変えたり、常識を疑い、世の中の「当たり前」を変えていきます。</p>
                        <p className="btn">
                          <TransitionLink to="/services" exit={{ length: transition.InitTransition.length, trigger: () => transition.animation() }} entry={{ delay: transition.InitTransition.delay }}>
                            <span>Explore all services</span>
                          </TransitionLink>
                        </p>
                        <figure className={Style.illustration}>
                          <Img
                            className={Style.illustrationImg}
                            fluid={data.illustration02.childImageSharp.fluid}
                            alt="Services & Value"
                          />
                        </figure>
                      </div>
                    </Observer>
                    <Observer {...options} onChange={this.servicesRight}>
                      <div className={`inner ${Style.right}`}>
                        <ul className={Style.servicesList}>
                          <li className={`init_a_servicesItem ${Style.servicesItem}`}>
                            <TransitionLink to="/services/strategy" exit={{ length: transition.InitTransition.length, trigger: () => transition.animation() }} entry={{ delay: transition.InitTransition.delay }}>
                              <h3>Strategy</h3>
                              <p className={Style.description}>メディア・広告・広報の戦略設計／課題発見のリサーチ＆分析／コンセプト立案 etc</p>
                              <div className="viewMore">
                                <svg
                                  className="arrow"
                                  version="1.1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  viewBox="0 0 125 20.4"
                                >
                                  <line x1="0" y1="10.2" x2="200" y2="10.2" />
                                  <polyline points="114.9,19.9 123.9,10.2 114.9,0.5" />
                                </svg>
                              </div>
                            </TransitionLink>
                          </li>
                          <li className={`init_a_servicesItem ${Style.servicesItem}`}>
                            <TransitionLink to="/services/contents" exit={{ length: transition.InitTransition.length, trigger: () => transition.animation() }} entry={{ delay: transition.InitTransition.delay }}>
                              <h3>Contents</h3>
                              <p className={Style.description}>コンテンツディレクション／テキストライティング／ムービー＆スチール撮影 etc</p>
                              <div className="viewMore">
                                <svg
                                  className="arrow"
                                  version="1.1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  viewBox="0 0 125 20.4"
                                >
                                  <line x1="0" y1="10.2" x2="200" y2="10.2" />
                                  <polyline points="114.9,19.9 123.9,10.2 114.9,0.5" />
                                </svg>
                              </div>
                            </TransitionLink>
                          </li>
                          <li className={`init_a_servicesItem ${Style.servicesItem}`}>
                            <TransitionLink to="/services/design" exit={{ length: transition.InitTransition.length, trigger: () => transition.animation() }} entry={{ delay: transition.InitTransition.delay }}>
                              <h3>Design</h3>
                              <p className={Style.description}>グラフィックデザイン／サイト・アプリのUIデザイン／エディトリアルデザイン etc</p>
                              <div className="viewMore">
                                <svg
                                  className="arrow"
                                  version="1.1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  viewBox="0 0 125 20.4"
                                >
                                  <line x1="0" y1="10.2" x2="200" y2="10.2" />
                                  <polyline points="114.9,19.9 123.9,10.2 114.9,0.5" />
                                </svg>
                              </div>
                            </TransitionLink>
                          </li>
                          <li className={`init_a_servicesItem ${Style.servicesItem}`}>
                            <TransitionLink to="/services/engineering" exit={{ length: transition.InitTransition.length, trigger: () => transition.animation() }} entry={{ delay: transition.InitTransition.delay }}>
                              <h3>Engineering</h3>
                              <p className={Style.description}>ウェブサイト設計・構築／ウェブシステム開発／ハイブリッドアプリ開発 etc</p>
                              <div className="viewMore">
                                <svg
                                  className="arrow"
                                  version="1.1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  viewBox="0 0 125 20.4"
                                >
                                  <line x1="0" y1="10.2" x2="200" y2="10.2" />
                                  <polyline points="114.9,19.9 123.9,10.2 114.9,0.5" />
                                </svg>
                              </div>
                            </TransitionLink>
                          </li>
                          <li className={`init_a_servicesItem ${Style.servicesItem}`}>
                            <TransitionLink to="/services/mediagrowth" exit={{ length: transition.InitTransition.length, trigger: () => transition.animation() }} entry={{ delay: transition.InitTransition.delay }}>
                              <h3>MediaGrowth</h3>
                              <p className={Style.description}>データコンサルティング／サイト分析＆改善提案／サイト＆SNSの運用サポート etc</p>
                              <div className="viewMore">
                                <svg
                                  className="arrow"
                                  version="1.1"
                                  xmlns="http://www.w3.org/2000/svg"
                                  xmlnsXlink="http://www.w3.org/1999/xlink"
                                  viewBox="0 0 125 20.4"
                                >
                                  <line x1="0" y1="10.2" x2="200" y2="10.2" />
                                  <polyline points="114.9,19.9 123.9,10.2 114.9,0.5" />
                                </svg>
                              </div>
                            </TransitionLink>
                          </li>
                        </ul>
                      </div>
                    </Observer>
                  </section>
                  <Observer {...options} onChange={this.works}>
                    <section className={`section ${Style.works}`}>
                      <h2 className="heading">
                        <span className="text textWorks">Works</span>
                        <span className="clip clipWorks init_a_headingWorks">Works</span>
                      </h2>
                      <p className={`intro init_a_introWorks ${Style.intro}`}>
                        新旧メディアのいずれに偏ることなく、
                        <br className="pcDisBlock" />
                        最適な「乗り物」にのせて届けます。
                      </p>
                      <PublishedPomagazine transition={transition} />
                      <PublishedArtWalkKyoto transition={transition} />
                      <PublishedDoya transition={transition} />
                      <PublishedSavvy transition={transition} />
                      <PublishedSeesaw transition={transition} />
                      <PublishedFoodDesign transition={transition} />
                      <PublishedKyotocityKyoceraMuseum transition={transition} />
                      <p className={`btn ${Style.btn}`}>
                        <TransitionLink to="/works" exit={{ length: transition.InitTransition.length, trigger: () => transition.animation() }} entry={{ delay: transition.InitTransition.delay }}>
                          <span>Explore our works</span>
                        </TransitionLink>
                      </p>
                    </section>
                  </Observer>
                  <Observer {...options} onChange={this.ourteam}>
                    <section className={`section ${Style.ourteam}`}>
                      <div className={Style.left}>
                        <h2 className="heading">
                          <span className="text">Our Team</span>
                          <span className="clip init_a_headingOurteam">Our Team</span>
                        </h2>
                        <p className="intro init_a_introOurteam">私たちの職能は「属人化した能力である」という考えを意識的に認め、とことん個人の成長に向き合ったチームを目指しています。</p>
                        <p className="btn">
                          <TransitionLink to="/ourteam" exit={{ length: transition.InitTransition.length, trigger: () => transition.animation() }} entry={{ delay: transition.InitTransition.delay }}>
                            <span>Introducing our team</span>
                          </TransitionLink>
                        </p>
                        {/* <p className={`btn ${Style.joinOurTeam}`}>
                          <TransitionLink to="/recruit" exit={{ length: transition.InitTransition.length, trigger: () => transition.animation() }} entry={{ delay: transition.InitTransition.delay }}>
                            <span>Join our team</span>
                          </TransitionLink>
                        </p> */}
                      </div>
                      <Parallax className={Style.illustration} x={[10, -10]} tagInner="figure">
                        <Img
                          className={Style.illustrationImg}
                          fluid={data.illustration03.childImageSharp.fluid}
                          alt="Our Team"
                        />
                      </Parallax>
                    </section>
                  </Observer>
                  <Observer {...options} onChange={this.culture}>
                    <section className={`section ${Style.culture}`}>
                      <div className={Style.right}>
                        <h2 className="heading">
                          <span className="text">Life at<br className="spDisBlock" /> bank to</span>
                          <span className="clip init_a_headingCulture">Life at<br className="spDisBlock" /> bank to</span>
                        </h2>
                        <p className={`intro init_a_introCulture ${Style.secIntro}`}>学ぶことに情熱をもち、新たな価値のために挑戦できる人を尊重しています。そのために、失敗を許容し、挑戦する風土や文化を育てる取り組みを行なっています。</p>
                        <p className="btn">
                          <TransitionLink to="/culture" exit={{ length: transition.InitTransition.length, trigger: () => transition.animation() }} entry={{ delay: transition.InitTransition.delay }}>
                            <span>Learn our culture</span>
                          </TransitionLink>
                        </p>
                      </div>
                      <Parallax className={Style.illustration} x={[-5, 5]} tagInner="figure">
                        <Img
                          className={Style.illustrationImg}
                          fluid={data.illustration04.childImageSharp.fluid}
                          alt="Life at bank to"
                        />
                      </Parallax>
                    </section>
                  </Observer>
                  {/*<Instagram />*/}
                </main>
              </ParallaxProvider>
              <Footer transition={transition} />
            </Transition>
          </Layout>
        )}
      />
    );
  }
}

export default Index;
